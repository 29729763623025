import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Logo from '../../assets/images/logo.svg';

import mail from '../../assets/images/mail.svg';
import linkedin from '../../assets/images/linkedin.svg';
import instagram from '../../assets/images/instagram.svg';

export function Footer() {

    const styles = useStyles();

    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.branding}>
                    <div className={`${styles.brandingMiddle} ${styles.middle}`}>
                        <Link className={styles.brandingLink} to="/">
                            <img src={Logo} alt="wistly logo" draggable="false" />
                            <div className={styles.title}> Wistly </div>
                        </Link>
                    </div>
                </div>
                <ul className={styles.social}>
                    <div className={styles.middle}>
                        <div className={styles.heading}> Contact Us </div>
                        <li className={styles.socialListing}>
                            <a className={styles.socialLinks} href="mailto:connect@wistly.io">
                                <img className={styles.icon} src={mail} alt="mail icon" draggable="false" />
                                connect@wistly.io
                            </a>
                        </li>
                        <li className={styles.socialListing}>
                            <a className={styles.socialLinks} href="https://www.instagram.com/wistly.io/">
                                <img className={styles.icon} src={instagram} alt="mail icon" draggable="false" />
                                wistly.io
                            </a>
                        </li>
                        <li className={styles.socialListing}>
                            <a className={styles.socialLinks} href="https://www.linkedin.com/company/wistlyio/">
                                <img className={styles.icon} src={linkedin} alt="mail icon" draggable="false" />
                                wistly
                            </a>
                        </li>
                    </div>
                </ul>
                <ul className={styles.information}>
                    <div className={styles.middle}>
                        <li className={styles.infoListing}>
                            <Link className={styles.infoLink} to="/help#privacy"> Privacy </Link>
                        </li>
                        <li className={styles.infoListing}>
                            <Link className={styles.infoLink} to="/help#tos"> Terms of Service </Link>
                        </li>
                        <li className={styles.infoListing}>
                            <Link className={styles.infoLink} to="/help#help"> Help </Link>
                        </li>
                        <li className={styles.infoListing}>
                            <Link className={styles.infoLink} to="/help#acessibility"> Accessibility </Link>
                        </li>
                    </div>
                </ul>
            </div>
        </footer>
    );
}

const useStyles = makeStyles({
    footer: {
        backgroundColor: "#F6F6F6",
        width: "100%",
    },
    container: {
        padding: "5%",
        display: "flex",
        margin: "auto",
        maxWidth: "1440px",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    icon: {
        marginRight: "10px"
    },
    branding: {
        marginBlockEnd: "1em",
        width: "20%"
    },
    brandingMiddle: {
        margin: "auto",
        maxWidth: "50px",
    },
    brandingLink: {
        textDecoration: "none"
    },
    links: {
        textDecoration: "none",
        color: "black"
    },
    title: {
        marginTop: "5px",
        fontWeight: "bold",
        color: "black"
    },
    social: {
        padding: 0,
        width: "40%",
        listStyleType: "none",
        marginBlockEnd: "1em",
    },
    socialLinks: {
        display: "flex",
        color: "#4650C5",
        fontWeight: "500",
        alignItems: "center"
    },
    socialListing: {
        marginTop: "15px"
    },
    middle: {
        margin: "auto",
        maxWidth: "200px",
    },
    heading: {
        fontWeight: "bold"
    },
    infoListing: {
        marginBottom: "20px"
    },
    information: {
        padding: 0,
        width: "40%",
        listStyleType: "none"
    },
    infoLink: {
        color: "black",
        fontWeight: "bold",
        textDecoration: "none"
    },
    "@media (max-width: 700px)": {
        branding: {
            width: "100%",
        },
        social: {
            width: "100%"
        },
        information: {
            width: "100%"
        },
        middle: {
            maxWidth: "100%"
        }
    }
});
