import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import wistlyLogo from '../../assets/images/logo.svg';
import { Button } from '../../assets/globalStyles';

export function NavBar() {
	const styles = useStyles();
    return (
        <nav className={styles.navbar}>
            <div className={styles.container}>
                <Link className={styles.branding} to="/">
                    <img className={styles.logo} src={wistlyLogo} alt="w-logo" draggable="false"/>
                    <div className={styles.name}> Wistly </div>
                </Link>
                <div className={styles.rightContainer}>
                    <Button className="button"> Coming soon to App Store </Button>
                </div>
            </div>
        </nav>
    );
}

const navbarHeight = "75px";
const useStyles = makeStyles({
    navbar: {
        width: "100%",
        backgroundColor: "#F6F6F6",
        height: navbarHeight
    },
    container: {
        width: "90%",
        height: "100%",
        maxWidth: "1440px",
        margin: "auto",
        justifyContent: "space-between",
        display: "flex"
    },
    branding: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "black"
    },
    logo: {
        width: `calc(${navbarHeight} - 30px)`,
        height: `calc(${navbarHeight} - 30px)`
    },
    name: {
        marginLeft: "10%",
        fontWeight: "bold",
        fontSize: "20px",
        alignSelf: "center"
    },
    rightContainer: {
        display: "flex",
        alignItems: "center"
    },
	"@media (max-width: 400px)": {
        rightContainer: {
            display: "none"
        }
    }
});