import { styled } from '@material-ui/core/styles';

export const Button = styled("button")({
    border: "none",
    backgroundColor: "#D7D7D7",
    color: "#4650C5",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    fontStyle: "italic",
    borderRadius: "24px",
    padding: "0.7rem 2rem 0.7rem 2rem",
    transition: "all 1s",
    "&:hover": {
        backgroundColor: "#c5c5c5"
    }
});
