import { makeStyles } from '@material-ui/core';

import {
    Hero
} from '../../component/hero';

import courierHandoff from '../../assets/images/Courier_Handoff2-01.svg';
import courierPackage from '../../assets/images/Courier_package_illustration-01.svg';
import pickupUI from '../../assets/images/Pickedup.png';

export function Home() {

    const styles = useStyles();

    return (
        <div className={styles.home}>
            <Hero
                heading="The safest way to deliver your packages"
                description="Here's a sentence about why Wistly is the safest way to send your packages."
                showLogo="true"
                invertColumn={false}
                buttonText="Coming soon to App Store">
                <div className={styles.imageryContainer}>
                    <img className={styles.image} src={courierHandoff} alt="courier handing off package" draggable="false" />
                </div>
            </Hero>
            <Hero
                heading="Immediate deliveries across the entire city"
                description="Packages are delivered by our trusty couriers, and tracking is available every step of the way. "
                showBackground="true"
                buttonText="Coming soon to App Store">
                <div className={styles.pickup1} style={{ backgroundImage: `url(${pickupUI})` }} draggable="false"></div>
                <div className={styles.pickup2} style={{ backgroundImage: `url(${pickupUI})` }} draggable="false"></div>
            </Hero>
            <Hero
                heading="Become a Wistly Courier"
                description="No matter if you’re on foot, a bike, or a car— you can earn big bucks on your own schedule."
                showBackground="true"
                buttonText="Coming soon to App Store">
                <div className={styles.imageryContainer}>
                    <img className={styles.courierPackage} src={courierPackage} alt="courier package" draggable="false" />
                </div>
            </Hero>
        </div>
    );
}

const useStyles = makeStyles({
    home: {
        maxWidth: "1440px",
        margin: "auto"
    },
    imageryContainer: {
        padding: "5%",
        width: "100%",
        justifyContent: "center",
        display: "flex"
    },
    image: {
        width: "100%",
        maxWidth: "30rem"
    },
    pickup1: {
        backgroundSize: "250px",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "-130px",
        width: "60%",
        height: "500px"
    },
    pickup2: {
        backgroundSize: "250px",
        backgroundRepeat: "no-repeat",
        width: "40%",
        backgroundPositionX: "40px",
        backgroundPositionY: "160px",
        height: "500px"
    },
    courierPackage: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "30%",
        maxWidth: "250px"
    },
    "@media (max-width: 1030px)": {
        pickup1: {
            display: "none"
        },
        pickup2: {
            width: "100%",
            backgroundPositionX: "center",
            backgroundPositionY: "-35px"
        }
    }
});