import {
    useEffect
} from 'react';

import wistlyLogo from '../../assets/images/logo.svg';

import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export function Help() {

    const { pathname, hash } = useLocation();

    useEffect(() => {
        const id = hash.replace("#", '');
        const element = document.getElementById(id);
        element?.scrollIntoView();
        window.scrollTo(0, window.scrollY - 10);
    }, [pathname, hash])

    const styles = useStyles();

    return (
        <div className={styles.help}>
            <div className={styles.header}>
                <img src={wistlyLogo} alt="w-logo" draggable="false" />
                <h1> More about Wistly </h1>
            </div>
            <div id="privacy" className={styles.section}>
                <h2 > Privacy </h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
            </div>
            <div id="tos" className={styles.section}>
                <h2> Terms of Service </h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
            </div>
            <div id="help" className={styles.section}>
                <h2> Help </h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
            </div>
            <div id="accessibility" className={styles.section}>
                <h2> Accessibility </h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus magna volutpat, non scelerisque integer adipiscing.
                    Malesuada dapibus augue turpis sit. Vivamus pulvinar donec amet, lectus. Eget sed faucibus in eu aliquet.
                </p>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    help: {
        maxWidth: "1440px",
        margin: "auto",
        padding: "50px 5% 0 5%",
        marginBlockEnd: "5rem"
    },
    header: {
        marginBlockEnd: "4rem",
        margin: "5% 5% 10% 5%",
    },
    section: {
        margin: "5% 5% 10% 5%",
        marginBlockEnd: "3rem"
    }
});