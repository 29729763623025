import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'

import {
    NavBar,
    ScrollToTop
} from './component/navigation'

import {
    Footer
} from './component/footer'

import {
    Home,
    Help,
} from './pages'

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <NavBar/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/help" component={Help}/>
                <Route path="/" component={Home}/>
            </Switch>
            <Footer/>
        </Router>
    );
}

export default App;
