
import { makeStyles } from '@material-ui/core';
import logo from '../../assets/images/logo.svg';
import { Button } from '../../assets/globalStyles';

export function Hero(props) {
    const {
        style,
        showBackground = false,
        showLogo = false,
        invertColumn = true,
        heading,
        description,
        buttonText,
        onClick,
        children
    } = props;

    const styles = useStyles();

    return (
        <div
            className={`${styles.hero} ${invertColumn && styles.reverseColumn}`}
            style={showBackground ? { backgroundColor: "#F7F7F7", ...style } : {}}>
            <div className={styles.content}>
                {showLogo && <img src={logo} alt="w-logo" draggable="false" />}
                <h1 className={styles.heading}> {heading} </h1>
                <h3 className={styles.description}> {description} </h3>
                <Button className={styles.button} onClick={onClick}> {buttonText} </Button>
            </div>
            <div className={styles.imagery}>
                {children}
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    hero: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "5% 5% 10% 5%",
        borderRadius: "25px",
    },
    heading: {
        fontSize: "46px"
    },
    content: {
        minHeight: "320px",
        margin: "auto",
        width: "50%",
        padding: "5%"
    },
    description: {
        fontWeight: 400
    },
    button: {
        marginTop: "5%"
    },
    imagery: {
        alignItems: "center",
        display: "flex",
        width: "50%"
    },
    "@media (max-width: 854px)": {
        hero: {
            flexWrap: "wrap",
        },
        content: {
            width: "90%"
        },
        imagery: {
            width: "100%"
        },
        reverseColumn: {
            flexDirection: "column-reverse"
        }
    }
});